import * as Sentry from '@sentry/react';
import axios from 'axios';
import { create } from 'zustand';
import { login as apiLogin } from '../api/login';
import { Profile } from '../type';


type LoginResponse = {
	status: number;
	data?: {
		multipleProfiles?: boolean;
		patients?: Profile[];
		message?: string;
	};
	response?: {
		data?: {
			error?: string;
		};
	};
}

type ProfileSelectionResponse = {
	success: boolean;
	error?: string;
}

type AuthState = {
	email?: string | undefined;
	hasAccessToken?: boolean;
	status: 'loggedOut' | 'loggedIn';
	selectedProfileId?: number;
};

type AuthActions = {
	login: (email: string, password: string) => Promise<LoginResponse>;
	loginToken: () => boolean;
	isLoggedIn: () => boolean;
	logout: () => Promise<void>;
	selectProfile: (profileId: number, email: string) => Promise<void>;
};

export type UseAuthStore = AuthState & AuthActions;

export const useAuthStore = create<UseAuthStore>((set, get) => ({
	email: undefined,
	status: 'loggedOut',
	selectedProfileId: undefined,

	isLoggedIn() {
		return get().status === 'loggedIn';
	},

	loginToken() {
		const hasAccessToken = document.cookie.indexOf('loggedIn=') >= 0;

		console.log('useAuthStore->loginToken', { hasAccessToken });

		if (hasAccessToken) {
			set({ status: 'loggedIn', hasAccessToken });
		}

		return hasAccessToken;
	},

	async login(email: string, password: string): Promise<LoginResponse> {
		const loginData = { email, password };

		try {
			const result = await apiLogin(loginData) as LoginResponse;

			if (!result || typeof result !== 'object') {
				throw new Error('Invalid response from server');
			}

			if (result.status === 200) {
				
				if (!result.data?.multipleProfiles && result.data?.patients?.length === 1) {
					set({
						email,
						status: 'loggedIn',
						selectedProfileId: result.data.patients[0].id
					});
				}
				return result;
			}

			throw new Error(result.response?.data?.error || 'Login failed');
		} catch (error) {
			Sentry.captureException(error);

			const errorResponse: LoginResponse = {
				status: 500,
				response: {
					data: {
						error: error instanceof Error ? error.message : 'Unknown error occurred'
					}
				}
			};
			throw errorResponse;
		}
	},

	async selectProfile(profileId: number, email: string) {
		try {
			Sentry.addBreadcrumb({
				type: 'store:useAuthStore',
				level: 'info',
				message: 'selectProfile: selecting profile',
				data: { profileId }
			});

			const response = await axios.post<ProfileSelectionResponse>(
				`${import.meta.env.VITE_APIV2}/apiv2/pt/select-profile`,
				{ profileId },
				{ withCredentials: true }
			);

			if (response.data.success) {
				
				set({
					email,
					status: 'loggedIn',
					selectedProfileId: profileId,
					hasAccessToken: true 
				});
			} else {
				throw new Error(response.data.error || 'Profile selection failed');
			}
		} catch (error) {
			Sentry.captureException({
				type: 'store:useAuthStore',
				category: 'api',
				message: 'selectProfile: profile selection failed',
				error,
			});
			throw error;
		}
	},

	async logout() {
		Sentry.addBreadcrumb({
			type: 'store:useAuthStore',
			level: 'info',
			message: 'logout: logout user and redirect to login page'
		});

		set({ email: undefined, status: 'loggedOut' });

		await axios.post(`${import.meta.env.VITE_APIV2}/apiv2/pt/logout`, {}, {
			withCredentials: true
		})
			.then(() => {
				console.log('useAuthStore', 'logout', 'successful');
				Sentry.addBreadcrumb({
					type: 'store:useAuthStore',
					category: 'api',
					level: 'info',
					message: 'logout: api/logout successful'
				});
			})
			.catch((error) => {
				Sentry.captureException({
					type: 'store:useAuthStore',
					category: 'api',
					message: 'logout: api/logout failed',
					error,
				})
			})

		Sentry.captureMessage("logging out")

		window.redirect('/login');
	},
}));

// @ts-ignore 
window.useAuthStore = useAuthStore;
